import React from "react";

function IntroPage() {
  const logo = process.env.PUBLIC_URL + 'BandScannerLogoSquareSemiTrans.png';
  return (
    <div className="intro-page">
      <div class="logo-scan-parent">
        <div class="logo-container">
          <img src={logo} alt="logo"/>
        </div>  
        <div class="circle-container">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="circle"></div>
        </div>
      </div>
      <div class="tagline">Your go-to source for discovering new music!</div>
      <div className="intro-links">
          <ul>
            <li><a href="/oxford" className="intro-link">Oxford</a></li>
            <li><a href="/bristol" className="intro-link">Bristol</a></li>
            <li><a href="/newcastle+upon+tyne" className="intro-link">Newcastle Upon Tyne</a></li>
          </ul>
      </div>
      <div class="email"><div> Want to add your event or have comments/questions?</div>Contact: <a href="mailto:blackwhippy@gmail.com">blackwhippy@gmail.com</a></div>
    </div>
  );
}

export default IntroPage;