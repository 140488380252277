import React from "react";
import "../css/VideoFooter.css";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

function VideoFooter({ band, venue, timestamp, cost, ticketURL }) {
  //console.log("Ticket URL:", ticketURL);
  let ticketLabel ="Tickets";

  // Create a new date object from the timestamp
  let date = new Date(timestamp * 1000);
  
  // Get the weekday as a string
  let weekday = date.toLocaleString("en-US", { weekday: "long" });
  
  // Get the month as a string
  let month = date.toLocaleString("en-US", { month: "long" });
  
  // Get the year as a string
  let year = date.getFullYear();
  
  // Get the day as a string
  let day = date.getDate();
  
  //get hour mins as a string
  let hour = date.getHours();
  // if mins are less than 10 add a 0
  let mins = date.getMinutes();
  if (mins < 10) {
    mins = "0" + mins;
  }

  // Combine the weekday, month, and year into a single string
  let dateString = `${weekday}, ${month} ${day}, ${year}, ${hour}:${mins}`;

  if (hour===0 && mins===0) {
    dateString = `${weekday}, ${month} ${day}, ${year}`;
  }

  //convert cost so it has 2 decimal places
  cost = cost.toFixed(2);

  // if link doesn't exist then set ticketLabel to ""
  if (!ticketURL) {
    ticketLabel = "Sold Out";
  }
  
  if (cost == 0.00) {
    cost = "";
    ticketLabel = "";
  } else {
  //add £ to cost
    cost = "£" + cost;
  }


    return (
      <div className="videoFooter">
        <div className="videoFooter_text">
          <h4>{band}</h4>
          <h3>@{venue}</h3>
          <text>{dateString}</text>
          <div className="cost-button-container">
            <p className="cost-text">{cost}</p>
            {ticketURL ? (
              <div className="buybutton">
                <a href={ticketURL} target="_blank" rel="noopener noreferrer">
                  <button>{ticketLabel}</button>
                </a>
              </div>
            ) : (
              <div className="buybutton">
              </div>
            )}
          </div>
        </div>
      </div>
    );
    
}

export default VideoFooter;
