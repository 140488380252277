import "../App.css";
import "@material-ui/icons";
import Video from "./Video";
import "@material-ui/core/styles";
import { Home } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import TopBar from "./TopBar";
import useScrollDirection from "../hooks/UseScrollDirection";

function App() {
  const navigate = useNavigate();
  const { scrollDir, scrollCallback } = useScrollDirection();
  const [videos, setVideos] = useState([]);
  const [pageFraction, setPageFraction] = useState(0)

  useEffect(() => {
    getFavVideos();
  }, []);

  const handleClearFavorites = () => {
    localStorage.removeItem("fav");
    setVideos([]);
    window.location.reload(false)
  }

  const parsedVideos = useMemo(() => {
    return videos?.map(v => ({...v})) ?? [];
  }, [videos]);

  const getFavVideos = async () => {
    console.log("getFavVideos")
    const vidos = localStorage.getItem("fav");
    if (vidos !== null) {
      const parsedVideos = JSON.parse(vidos);
      setVideos(parsedVideos);
    }
  };

  return (
    <div className="app">
      <TopBar position="static"/>
      <div
        className="app_videos"
        onScroll={(e) => {
          scrollCallback(e.target.scrollTop);
          const f = e.target.scrollTop / e.target.clientHeight;
          const newPage = Math.round(f);
          setPageFraction(newPage);
        }}
      >
        {videos?.length &&
          videos?.map((v, i) => {
            return (
              <Video
                key={v.url}
                index={i}
                item={v}
                page={pageFraction}
                scrollDir={scrollDir}
                {...v}
              />
            );
          })}
      </div>
      <div className="debug" style={{ zIndex:1000}}>
        Favourites : {pageFraction + 1} / {videos?.length}
        <div className="favbutton">
        <button onClick={handleClearFavorites}>Clear</button>
      </div>
      </div>
    </div>
  );
}

export default App;
