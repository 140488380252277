import { FireSQL } from 'firesql';
import firebase from 'firebase/compat/app';
import { useState, useEffect, useCallback } from "react";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  orderBy,
  startAfter,
  where,
} from "firebase/firestore";
import { create } from "zustand";
import moment from "moment";
import { GetDBNameFromParams } from "../components/URLParameters";

const pageSize = 6;
let dbName="TruckFest";

// define function to detect the string is lower case
function isLowercase(str) {
  return str === str.toLowerCase();
}

export const useVideoStore = create((set) => ({
  videos: [],
  setVideos: (videos) => set({ videos }),
}));

export function useVideos() {
  // const [videos, setVideos] = useState(new Map());
  // we can useVideoStore in lots of different places and they will all share the same state
  // some of the other state in here may want to be shunted into zustand as well
  // or we might have one hook (not useVideos and useVideoStore)
  // current design is not necessarily the best...
  // but at least it doesn't matter *too* much re-using this hook in the way we are.
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(null);
  const { videos, setVideos } = useVideoStore();
  const [loading, setLoading] = useState(false);
  dbName = GetDBNameFromParams()
  
  const getData = async () => {
    setLoading(true);
    const docSnap = await getVideos();
    const newMap = [];
    if (videos.length) {
      newMap.push(...videos);
    }
    docSnap.forEach((doc) => newMap.push({...doc.data(), id: doc.id}));
    setVideos(newMap);
    setStart(docSnap.docs[docSnap.docs.length - 1]);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [setVideos]);

  const nextPage = useCallback(
    async function nextPage() {
      if (loading) return;
      const docSnap = await getVideos(start);

      const newMap = [];
      if (videos.length) {
        newMap.push(...videos);
      }
      docSnap.forEach((doc) => newMap.push({...doc.data(), id: doc.id}));
      //sort newMap by band name
      //newMap.sort((a, b) => a.band.localeCompare(b.band));
      setVideos(newMap); //don't use the callback form of setVideos, it breaks with zustand
      setStart(docSnap.docs[docSnap.docs.length - 1]);
      setLoading(false);
    },
    [start, loading, setVideos, videos]
  );

  useEffect(() => {
    if (videos.length <= 3) return;
    if (page > videos.length - 3) {
      nextPage();
    }
  }, [page, videos, nextPage]);

  function convertToTitleCase(word) {
    if (!word) return '';
    const words = word.split(' ');
    const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return titleCaseWords.join(' ');
  }


  async function getBandVideos(bandName, location, startPrice, endPrice, startDate, endDate, ticketURL) {
     // make first letter upper case
    const dbRef = firebase.firestore();
    const fireSQL = new FireSQL(dbRef);

    // convert words to lower case and first letter to upper case
    let bandNameConverted = convertToTitleCase(bandName);
    //let locationConverted = convertToTitleCase(location);
    let locationConverted = location;
    const startTimeStamp = moment(startDate).unix();
    // end date is 2 years after start date
    const endTimeStamp = moment(startDate).add(2, 'years').unix();
    //const endTimeStamp = endDate ? moment(endDate).unix() : null;

    //annoying firebase can only query on one field at a time so have to do 2 queries

    let bandQuery = bandNameConverted ? "SELECT * FROM " + dbName + " WHERE (band LIKE '" + bandNameConverted + "%' OR band LIKE 'The " + bandNameConverted +"%')": "";
    let locationQuery = locationConverted ? "SELECT * FROM " + dbName + " WHERE town = '" + locationConverted + "'": "";
    let dateQuery = startDate && endDate ? "SELECT * FROM " + dbName + " WHERE timestamp BETWEEN " + startTimeStamp + " AND " + endTimeStamp : "";
    console.log("startDate = "+startDate + " endDate = "+endDate)


    let bandDoc = []
    let locDoc = [];
    let dateDoc = [];
    let documents = [];
    let intersection = []

    if (bandName){
      documents = await fireSQL.query(bandQuery);
      documents.forEach((doc) => bandDoc.push({...doc, id: doc.id}));
      //console.log("bandQuery = "+bandQuery)
    }

    if (location) {
      documents = await fireSQL.query(locationQuery);
      documents.forEach((doc) => locDoc.push({...doc, id: doc.id}));
      //console.log("locationQuery = "+locationQuery)
    }

    if (startDate && endDate) {
      //console.log("dateQuery = "+dateQuery)
      documents = await fireSQL.query(dateQuery);
      documents.forEach((doc) => dateDoc.push({...doc, id: doc.id}));
    }

    //console.log("lengths = "+bandDoc.length + " " + locDoc.length + " " + dateDoc.length + "")

    //get intersection of bandDoc and locDoc and dateDoc
    //horribly contrived since can't do proper sql in firebase!!!
    if (bandName && location && startDate && endDate) {
      intersection = bandDoc.filter(x => locDoc.some(y => y.url === x.url) && dateDoc.some(y => y.url === x.url));
    } else if (bandName && location) {
      intersection = bandDoc.filter(x => locDoc.some(y => y.url === x.url));
    } else if (bandName && startDate && endDate) {
      intersection = bandDoc.filter(x => dateDoc.some(y => y.url === x.url));
    } else if (location && startDate && endDate) {
      intersection = locDoc.filter(x => dateDoc.some(y => y.url === x.url));
    } else if (bandName) {
      intersection = bandDoc;
    } else if (location) {
      intersection = locDoc;
    } else if (startDate && endDate) {
      intersection = dateDoc;
    }
  
    //console.log("intersection = "+JSON.stringify(intersection));

    return intersection;
  }


  const searchVideo = async (props) => {
    let res = []
    const { location, bandName, startPrice, endPrice, startDate, endDate, setTotalSizeCallback } =  props;
    console.log("searchVideo query"+bandName)
    res = await getBandVideos(bandName,location,startPrice,endPrice,startDate,endDate);
    //console.log("results = "+JSON.stringify(res));
    // this is a bit of a hack to get the total size of the results from Search.jsx

    if (res.length === 0) {
      alert("No results found");
      return;
    }

    if (setTotalSizeCallback) {
      setTotalSizeCallback(res.length);
    }

    setVideos(res);
    setLoading(false);
  };

  return {
    videos,
    page,
    setPage,
    getData,
    searchVideo,
  }; // also could return nextPage and searchForBand
}

async function getVideos(start) {
  const colRef = collection(db, dbName);
  const startOfToday = new Date();
  // get the current timestamp down to the second
  const timeNow = new Date().getTime()/1000;
  console.log("timeNow ", timeNow);
  // ST changed 26/3/2023 may need to not do this since this messes up the scheduling on the dat
  // if you want to use it as a planner
  //startOfToday.setHours(0, 0, 0); // set time to 00:00:00.000

  //remove seconds and milliseconds and store in startOfToday
  //only return minutes not seconds
  startOfToday.setSeconds(0, 0);
  // show the data in the console
  const startOfTodayTimestamp = Math.floor(startOfToday.getTime() / 1000); // remove milliseconds
  
  //console.log("startOfTodayTimestamp ", startOfTodayTimestamp)
  //console.log("startOfToday ", startOfToday)
  //console.log("TIme ", startOfToday.getTime())
  const sortBy = "band";

  let allVideos = document.getElementsByTagName('video');
  for(let i=0; i<allVideos.length; i++) {
      allVideos[i].pause();
  }
  
  /* yuk Events where we do not no the times are mixed case 
  and then ordered alpabetically due to the fact times
   and dates are normally late to be published */
  if (!isLowercase(dbName)) {
    if (start) {
      const q = query(
        colRef,
        orderBy(sortBy),
        startAfter(start),
        limit(pageSize)
      );
      return await getDocs(q);
      } else {
        const q = query(
          colRef,
          orderBy(sortBy),
          limit(pageSize));
          return await getDocs(q);
      }
    } 
  /* Clashfinder or gigs with times are are ALL lowercase 
  to show they should be ordered by time */
  if (isLowercase(dbName)) {
    if (start) {
      const q = query(
        colRef,
        where("timestamp", ">",timeNow),
        orderBy("timestamp"),
        startAfter(start),
        limit(pageSize)
      );
      return await getDocs(q);
    } else {
      const q = query(colRef,
        where("timestamp", ">",timeNow),
        orderBy("timestamp"), 
        limit(pageSize));
      return await getDocs(q);
    }

    
}
}