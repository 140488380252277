import React, { useState } from "react";
import "../css/VideoSidebar.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MessageIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";

function VideoSidebar({ liked, onUnSave, shares, messages, onSave }) {
  return (
    <div className="videoSidebar">
      <div className="videoSidebar_button">
        {liked ? (
          <FavoriteIcon fontSize="large" onClick={onUnSave} />
        ) : (
          <FavoriteBorderIcon fontSize="large" onClick={onSave} />
        )}
        {/* <p>{liked ? likes + 1 : likes}</p> */}
      </div>
    </div>
  );
}

export default VideoSidebar;
