import { useState, useCallback } from 'react';

function useScrollDirection() {
  const [scrollDir, setScrollDir] = useState();
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [restingTimeout, setRestingTimeout] = useState();
  const scrollCallback = useCallback(
    (scrollTop) => {
      if (restingTimeout) {
        clearTimeout(restingTimeout);
      }
      const timeout = setTimeout(() => {
        setScrollDir(null);
      }, 100);
      setRestingTimeout(timeout);
      setScrollDir(scrollTop > lastScrollPos ? 'down' : 'up');
      setLastScrollPos(scrollTop);
    },
    [lastScrollPos, restingTimeout]
  );
  return { scrollDir, scrollCallback };
}
export default useScrollDirection;