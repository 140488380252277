import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Badge, IconButton } from "@material-ui/core";
import { Home, Search, Favorite } from "@material-ui/icons";
import { useNavigate, Link } from "react-router-dom";
import { GetDBNameFromParams } from "./URLParameters";
import logoImage from "./images/BandScannerLogo.PNG";

function CapitalizeFirstLetter(str) {
  return str.replace(/_/g, " ").replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

function TopBar({ onOpenSearch, onFavouriteClick }) {
  const navigate = useNavigate();
  const [showFavourites, setShowFavourites] = useState(false);
  const subTitle = CapitalizeFirstLetter(GetDBNameFromParams());
  const [vidosLength, setVidosLength] = useState(0);

  useEffect(() => {
    const updateBadgeContent = () => {
      const vidos = localStorage.getItem("fav")
        ? JSON.parse(localStorage.getItem("fav")).length
        : 0;
      setVidosLength(vidos);
    };

    const handleFavVideosChanged = () => {
      console.log("Fav videos changed event received");
      updateBadgeContent();
    };

    updateBadgeContent();
    window.addEventListener("favVideosUpdated", handleFavVideosChanged);

    return () => {
      window.removeEventListener("favVideosUpdated", handleFavVideosChanged);
    };
  }, []);

  const handleFavouriteClick = () => {
    const vidos = localStorage.getItem("fav");
    //console.log("vidos", vidos);
    if (vidos === null) {
      alert(
        "No favourites, please add some videos to your favourites list using the heart icon."
      );
      return;
    }

    setShowFavourites(!showFavourites);
    if (onFavouriteClick) {
      onFavouriteClick(!showFavourites);
    }
  };

  return (
    <>
      <AppBar position="static" style={{ width: "100vw" }}>
        <Toolbar style={{ justifyContent: "space-between", flex: "1" }}>
          <Link to="/">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={logoImage} alt="Logo" className="app_logo" style={{ height: "40px", marginRight: "10px" }} />
            <div className="app_title">BANDSCANNER</div>
          </div>
          </Link>
          <div style={{ display: "flex" }}>
            <IconButton color="inherit" onClick={handleFavouriteClick}>
              <Badge badgeContent={vidosLength} color="secondary">
                <Favorite style={{ color: "black" }} />
              </Badge>
            </IconButton>
            <IconButton color="inherit" onClick={onOpenSearch}>
              <Search style={{ color: "black" }} />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => window.location.reload(false)}
            >
              <Home style={{ color: "black" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className="app_sub_title">{subTitle}</div>
    </>
  );
}

export default TopBar;
