import React, { useCallback, useEffect, useState } from "react";
import VideoFooter from "./VideoFooter";
import VideoSidebar from "./VideoSidebar";
import ReactPlayer from "react-player";
import "../css/Video.css";
import Tooltip from '@mui/material/Tooltip';

export default function Video({
  url,
  band,
  venue,
  timestamp,
  cost,
  page,
  index,
  scrollDir,
  item,
  onScrollUp,
  onScrollDown,
  ticketURL,
}) {
  // const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [liked, setLiked] = useState(false);
  //yuk
  const [playClicked, setPlayClicked] = useState(false)

  const playerRef = React.useRef();
  const onReady = React.useCallback(() => {
    playerRef.current.seekTo(0.25, "fraction");
  }, []);

   useEffect(() => {
    const shouldPlay = page === index && !scrollDir;
    // this cause autoplay on mobile
    setPlaying(false);
    //cleanup function
    return () => {
      if (!shouldPlay) {
        setPlaying(false);
      }
    };
  }, [index, page, scrollDir]);

  const checkFav = useCallback(async () => {
    let vidos = await localStorage.getItem("fav");
    if (vidos !== null) {
      let parsVidos = JSON.parse(vidos);
      if (parsVidos?.length) {
        let ar = parsVidos.find((E) => E.id === item.id);
        if (ar) {
          setLiked(true);
        }
      }
    }
  }, [item.id]);

  useEffect(() => {
    checkFav();
  }, [checkFav]);

  const onSave = async () => {
    //yuk update badgeEvent
    console.log("onSave");

    setLiked(true);
    let vidos = await localStorage.getItem("fav");
    if (vidos !== null) {
      let parsVidos = JSON.parse(vidos);
      if (parsVidos?.length) {
        let ar = [...parsVidos, item];
        await localStorage.setItem("fav", JSON.stringify(ar));
      } else {
        await localStorage.setItem("fav", JSON.stringify([item]));
      }
    } else {
      await localStorage.setItem("fav", JSON.stringify([item]));
    }
    window.dispatchEvent(new CustomEvent("favVideosUpdated"));
  };

  const onUnSave = async () => {
    setLiked(false);
    let vidos = await localStorage.getItem("fav");
    if (vidos !== null) {
      let parsVidos = JSON.parse(vidos);
      if (parsVidos?.length) {
        let ar = parsVidos.filter((e) => e.id !== item.id);
        await localStorage.setItem("fav", JSON.stringify(ar));
      }
    }
    window.dispatchEvent(new CustomEvent("favVideosUpdated"));
  };

  //yuk  
  const onPlay = () => {
    setPlayClicked(true); // Update the state when the video starts playing
    setPlaying(true);
  }

  return (
    <div className="video" page={page}>
          <ReactPlayer
            ref={playerRef}
            url={"https://www.youtube.com/watch?v=" + url}
            width="100%"
            height="80%"
            //yuk
            onPlay={onPlay}
            controls={true}
            key={url}
            //show play/pause button
            playing={playing}
            //needed to autoplay on iOS
            muted={false}
          />
          {playClicked && <div className="overlay" onClick={() => setPlaying(!playing)}></div>} {/* yuk, fix for iphone */}
      <VideoFooter
        band={band}
        venue={venue}
        timestamp={timestamp}
        cost={cost}
        ticketURL={ticketURL}
      />
      <VideoSidebar
        onSave={onSave}
        onUnSave={onUnSave}
        liked={liked}
        messages={0}
        shares={0}
      />

    </div>
  );
}
