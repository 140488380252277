import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyCDM9Zf5C5_xQQQVU6772vxQO90IGWJ_xA",
   authDomain: "bandscanner-e70bc.firebaseapp.com",
   projectId: "bandscanner-e70bc",
   storageBucket: "bandscanner-e70bc.appspot.com",
   messagingSenderId: "508471818880",
   appId: "1:508471818880:web:b437403bd137b8f36b0465",
   measurementId: "G-6D24SXFS89"
 };

const firebaseApp = firebase.initializeApp(firebaseConfig)
export const db = firebaseApp.firestore();
//export const auth = firebase.auth();
//export { auth, db };
//export default firebase.initializeApp(firebaseConfig)