import "../App.css";
import React from "react";
import SearchScreen from "./Search";
import VideoScreen from "../hooks/VideoData";
import IntroPage from "./IntroPage"
import { Search } from "@material-ui/icons";
import { Routes, Route, useParams } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/:dbName" element={<SearchScreen />} />
      </Routes>
      <Analytics />
    </div>
  );
}

export default App;