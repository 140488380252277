import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Modal,
  Paper,
  Button,
  AppBar,
  Toolbar,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import "../App.css";
import "@material-ui/icons";
import Video from "./Video";
import "@material-ui/core/styles";
import { Search, Favorite } from "@material-ui/icons";
import { useVideos } from "../hooks/VideoData";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { Home } from "@material-ui/icons";
import { useParams, Link } from "react-router-dom";
import { GetDBNameFromParams } from "./URLParameters";
import Favourite from "./Favourite";
import TopBar from "./TopBar";
import useScrollDirection from "../hooks/UseScrollDirection";

let dbName = "";

const useStyles = makeStyles((theme) => ({
  paper: {
    fontFamily: 'Harvest',
    width: 300,
    top: "50%",
    left: "50%",
    position: "absolute",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.background.paper,
  },
  greenButton: {
    fontFamily: 'Harvest',
    backgroundColor: "#4CAF50",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#388E3C",
    },
  },
}));

function App() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { scrollDir, scrollCallback } = useScrollDirection();
  const { videos, page, setPage, searchVideo, getData} = useVideos();

  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [endPrice, setEndPrice] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startPrice, setStartPrice] = useState("");
  const [pageFraction, setPageFraction] = useState(0);
  const [bandName, setBandName] = useState("");
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [showFavourites, setShowFavourites] = useState(false);


  dbName = GetDBNameFromParams();

  const handleSubmit = () => {
    searchVideo({
      bandName,
      location,
      startPrice,
      endPrice,
      startDate,
      endDate,
      setTotalSizeCallback: setTotalSize, // Pass the setTotalSize function as a callback
    });
    setOpen(false);
  };

  const handleFavouriteClick = () => {
    setShowFavourites(!showFavourites);
  };
  
  const handleOpenSearch = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClear = (event) => {
    event.preventDefault();
    getData();
    setOpen(false);
    setEndDate("");
    setEndPrice("");
    setLocation("");
    setBandName("");
    setStartDate("");
    setStartPrice("");
  };

  useEffect(() => {
    const getLocations = async () => {
      const snapshot = await firebase.firestore().collection(dbName).get();
      const locationData = [...new Set(snapshot.docs.map((doc) => doc.data().town))];
      setLocations(locationData);
    };

    // get the total query size based on the number of events in the future 
    // this is less than the total number of events in the database
    const timeNow = new Date().getTime()/1000;
    const querySize = firebase.firestore().collection(dbName).where("timestamp", ">=", timeNow).get().then((snapshot) => {
      //console.log("Total query size: " + snapshot.size);
      setTotalSize(snapshot.size);
    });

    /*const querySize = firebase.firestore().collection(dbName).get().then((snapshot) => {
      //console.log("Total query size: " + snapshot.size);
      setTotalSize(snapshot.size);
    }); */
    
    // print all the locations
    //console.log("locations = " + locations)
    getLocations();
  }, []);
  

  return (
    <div className="app">
    {!showFavourites && (
    <>
    <TopBar onOpenSearch={handleOpenSearch} onFavouriteClick={handleFavouriteClick} />
      <div
        className="app_videos"
        onScroll={(e) => {
          scrollCallback(e.target.scrollTop);
          const f = e.target.scrollTop / e.target.clientHeight;
          const newPage = Math.round(f);
          setPageFraction(newPage);
          if (newPage !== page) setPage(newPage)
        }}
      >
        {videos.map((v, i) => {
          return (
            <Video
              key={v.url}
              index={i}
              page={pageFraction}
              scrollDir={scrollDir}
              item={v}
              {...v}
            />
          );
        })}
      </div>
      </>
      )}
      {showFavourites && (<Favourite setShowFavourites={setShowFavourites} />)} 
      <div className="debug">
       Events : {page >= totalSize ? page - (totalSize * (Math.floor(page/totalSize))) + 1 : page + 1 } / {totalSize}
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper className={classes.paper}>
          <div className="modal">
            <h2>Filter Video</h2>
            <br />
            <TextField
              label="Band Name"
              fullWidth
              value={bandName}
              onChange={(event) => setBandName(event.target.value)}
            />
            <br />
            <br />
            <TextField
            fullWidth
            select
            /*label="Location"*/
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            SelectProps={{
              native: true,
            }}
            >
            <option value="">Select a location</option>
            {locations.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
            </TextField>
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <TextField
                fullWidth
                type={"date"}
                value={startDate}
                style={{ marginRight: 5 }}
                onChange={(event) => setStartDate(event.target.value)}
              />
              <TextField
                fullWidth
                type={"date"}
                value={endDate}
                style={{ marginLeft: 5 }}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <Button
                fullWidth
                className={classes.greenButton}
                variant="contained"
                onClick={handleClear}
                style={{ marginRight: 5 }}
              >
                Cancel
              </Button>
              <Button
                className={classes.greenButton}
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                style={{ marginLeft: 5 }}
              >
                Search
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </div>
  );
}

export default App;
