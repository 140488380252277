import { useParams, useLocation } from "react-router-dom";
const defaultDB="oxford";


export function GetDBNameFromParams() {
    let dbName = defaultDB;
    dbName = useParams();
    //console.log("dbName 1 = ", dbName);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const abc = searchParams.get("abc"); 
    //console.log("abc = ", abc);

    // convert to a string with just the value of the object
    dbName = JSON.stringify(dbName);
    // remove the curly braces
    dbName = dbName.replace("{", "");
    dbName = dbName.replace("}", "");
    // remove the quotes
    dbName = dbName.replace(/['"]+/g, '');
    // remove the dbName:
    dbName = dbName.replace("dbName:", "");
    // remove the space
    dbName = dbName.replace(" ", "");
    // show the data in the console
    //console.log("dbName ", dbName);
    if (dbName === '/') {
      dbName = defaultDB;
    }
  
    return dbName;
}